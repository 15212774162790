import Footer from "./Footer.js"; 
import AuthJSON from "../Auth.json";
import NFTTile from "./NFTTile";
import axios from "axios";
import React, { useEffect, useState } from 'react';

export default function Authentication() {
const sampleData = [
	{
		"name": "The Brain Activity Map",
		"tokenId": "21",
		"description": "Brain mapping initiative",
		"website":"https://theneomedium.com",
		"hash": "QmRBeX6Ag16omRMsG4bHWAu3C1FT9r19LtgVh3s6Fk6ARJ",
		"file":"https://gateway.pinata.cloud/ipfs/QmRBeX6Ag16omRMsG4bHWAu3C1FT9r19LtgVh3s6Fk6ARJ",
   	 	"address":"0xC66D5AdDb3e8Bdf4575a0d95c08009d7AEfD88B2",
	},
	{
		"name": "The Holy Bible KJV Edinburgh (1793)",
		"tokenId": "22",
		"description": "The Old And New Testament Translated Out Of The Original Tongues",
		"website":"https://theneomedium.com",
		"hash":"QmTroTTEVEZKPRXJd1tsyg7w1oUmuxxF6j2tBKk1hfeHry",
		"file":"https://gateway.pinata.cloud/ipfs/QmXXh18hQhLjcAkD1Lkg6M6DbfvhkvL48so1C2h5YaXmgH",
		"address":"0xC66D5AdDb3e8Bdf4575a0d95c08009d7AEfD88B2",
	},
	{
		"name": "Shakespeare",
		"tokenId": "23",
		"description": "Shakespeare",
		"website":"http://axieinfinity.io",
		"hash":"na",
		"file":"https://indigo-bitter-stork-403.mypinata.cloud/ipfs/QmYkYCCScddypng8J9s5gqZRgonLuUvS7nmbhZM4Dj7ai5",
		"address":"0xe81Bf5A757C4f7F82a2F23b1e59bE45c33c5b13",
	},
	{
		"name": "The Bible",
		"tokenId": "24",
		"description": "King James Version",
		"website":"http://axieinfinity.io",
		"hash":"na",
		"file":"https://indigo-bitter-stork-403.mypinata.cloud/ipfs/QmSNcY6sLqyLLRSYuP61XgPhK5JL5VoiXJM7LSQxbXFUrt",
		"address":"0xe81Bf5A757C4f7F82a2F23b1e59bE45c33c5b13",
	},
	{
		"name": "Isaac's Favorite",
		"tokenId": "25",
		"description": "This is a good one!",
		"website":"http://axieinfinity.io",
		"hash":"d273685b95de9eb7ce5244c2d9beb8c6",
		"file":"https://indigo-bitter-stork-403.mypinata.cloud/ipfs/QmQ69AjrbxvGhWpgQPB9WJYmQdosb7vdjwJdXNgm5eD23Q",
		"address":"0xe81Bf5A757C4f7F82a2F23b1e59bE45c33c5b13",
	},
];
	
const [data, updateData] = useState([]);
const [dataFetched, updateFetched] = useState(false);
const [isOpen, setIsOpen] = useState(true);
const [file, setFile] = useState();
const [res, setRes] = useState([]);
const [fileCID, setFileCid] = useState();
const [uploadedFile, setUploadedFile] = useState();

// grok code 
async function getAllFiles() {
  let serv_url = 'https://theneomedium.com/list';
  
  try {
    const response = await axios.get(serv_url);
    console.log(response.data.rows[0].ipfs_pin_hash);
	console.log(response.data.rows);
    setFileCid(response.data.ipfs_pin_hash); 
    setUploadedFile('https://gateway.pinata.cloud/ipfs/' + response.data.ipfs_pin_hash);
    updateData(response.data.rows); // Assuming you want to display rows
    updateFetched(true);
  } catch (error) {
    console.error("Error fetching data:", error);
    alert("Error Retrieving NFTs");
  }
}


useEffect(() => {
  getAllFiles();
}, []); // Empty dependency array means this runs once on mount
//*****************

function List({ children }) {
	return (
	<ul className="slate-100">
		{children}
	</ul>
  )
};

if(!dataFetched)
    try {
		getAllFiles();
	}
    catch(e) {
        alert( "Error Retrieving Files" )
    }
return (
<div class="px-2 py-2" >
	<div class="px-2 py-2">
		<div class="px-2 py-2">
			 
			 <p className="flow-text text-md ml-10 mr-10 mb-10 break-word">
			 The goal of this site is to provide access to information that is tamper-resistant and decentralized.  The files you find on this site are stored on the InterPlanetary File System, <a target="_blank" rel="noopener noreferrer" href = "https://ipfs.tech/">IPFS< / a>.  IPFS is an open system to manage data without a central server.  <a target="_blank" rel="noopener noreferrer" href = "https://ethereum.org/en/">Ethereum< / a> is also in the pipeline as a method of validating the authenticity of the files pinned to IPFS.  The plan is to bolster Ethereum's core innovation:
			 </p>
			 <p>
			 <i>"The core innovation is that you can do all this without trusting a central authority that could change the rules or restrict your access."</i>
			 </p>
			 <p>
			 You can save and access books, articles, or really any information on IPFS through this site.
			 </p>
			 <div class="row text-center">
			 <div class="span4"><img src="diagram.png" class="img-fluid" alt="Responsive image"/></div>
		</div>
			<form>
			 <input type='button' onClick={getAllFiles} value = "Refresh Data" />
		    </form>
	</div>

</div>

<table class="table table-striped table-dark">
<tbody>
  {data.map((item, index) => (
    <tr key={index}>
      <th scope="row">{index + 1}</th>
      <td><a target="_blank" rel="noopener noreferrer" href={'https://gateway.pinata.cloud/ipfs/' + item.ipfs_pin_hash}>{'https://gateway.pinata.cloud/ipfs/' + item.ipfs_pin_hash}</a></td>
      <td>{item.metadata.name}</td>
      <td>{item.date_pinned}</td>
    </tr>
  ))}
</tbody>
</table>

	<table class="table table-striped table-dark">
		<thead>
		<tr>
			<th scope="col">#</th>
			<th scope="col">Title</th>
			<th scope="col">Description</th>
			<th scope="col">Source</th>
		</tr>
		</thead>
		<tbody>
		<tr>
			<th scope="row">1</th>
			<td><a target="_blank" rel="noopener noreferrer" href = "https://gateway.pinata.cloud/ipfs/QmRA3NWM82ZGynMbYzAgYTSXCVM14Wx1RZ8fKP42G6gjgj">Bitcoin: A Peer-to-Peer Electronic Cash System< / a></td>
			<td>Satoshi Nakamoto</td>
			<td>Satoshi Nakamoto</td>
		</tr>
		<tr>
			<th scope="row">2</th>
			<td><a target="_blank" rel="noopener noreferrer" href = "https://gateway.pinata.cloud/ipfs/QmTroTTEVEZKPRXJd1tsyg7w1oUmuxxF6j2tBKk1hfeHry">The Holy Bible KJV Edinburgh (1793)< / a></td>
			<td>The Old And New Testament Translated Out Of The Original Tongues</td> 
			<td>https://commons.wikimedia.org/wiki/File:The_Holy_Bible_KJV_Edinburgh_(1793).pdf</td>
		</tr>
		<tr>
			<th scope="row">3</th>
			<td>Shakespeare</td>
			<td>All of Shakespeare's works</td>
			<td>Folger Shakespeare Library</td>
		</tr>
		</tbody>
	</table>
	<Footer/>
</div>
);};
